
import React from 'react';
import './CardHome.css';

const Card = ({ title, content, Icon }) => {
  return (
    <div className="cardh">
      {Icon && <Icon className="cardh-icon" />}
      <div className="cardh-content">
        <h2 className="cardh-title">{title}</h2>
        <p className="cardh-text">{content}</p>
      </div>
    </div>
  );
};

export default Card;
