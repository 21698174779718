import React, { useState } from 'react'
import "./Card.css"
import "./Assets.js"
import ReactCardFlip from 'react-card-flip';

const Card = ({title,description, icon,price,pointers,notpointers, onClick }) => {
  const [isFlipped,setIsFlipped]=useState(false);

  const flipcard=()=>{
    setIsFlipped(!isFlipped);
  }
    return (
      <div>
       
        <div className="card">
        
          <div className="card-content">
            <h3 className="card-title">{title}</h3>
            <p className="card-description">{description}</p>
            
            <p className='pointers'>{pointers}</p>
            <p className='notpointers'>{notpointers}</p>
          </div>
        </div>
     
 
      </div>
    );
  };

export default Card