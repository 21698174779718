import React from 'react'

import home3 from '../Images/home3.png';


import blog3 from '../Images/blog3removebg.png';
import wa from "../Images/wa.png" ;
import './Home.css';
import './transition.css';
import Card from './CardHome';
import { FiCpu } from "react-icons/fi";
import Brand from './BrandLogo';
import HomeDiv from './HomeDiv';
import HomeQues from './HomeQues';

import { Link } from 'react-router-dom';

const Home = () => {
  document.title=" GroGlobally | Home"
  return (
    
          <div className="main-section">
       <div className='contbutton'>
        <Link to="https://api.whatsapp.com/send?phone=918377059526">
         
            <img src={wa} alt='wastaap' />
            
         
          
        </Link>
            </div> 

    <div className="home-container">
      
      <div className="home-content">
        <h1>Boost Your </h1>
         <div className='headin'><h1><span class="heading-span">Online  </span>   Presence</h1></div>
    
        <p class="main-para">Your Vision, Our strategy, Real Results
        </p>
        <Link to={'/services'}><button className="btn-home">Get Strarted</button></Link>
      </div>
      
    </div>

    <div class="home-card">
      <Card 
        title="Fast" 
        content="Specialized Teams and Data-Driven Decisions" 
        Icon={FiCpu} 
      />
      <Card 
        title="Efficient" 
        content="Client Communication and Cost Management" 
        Icon={FiCpu} 
       />
      <Card 
        title="Reliable" 
        content="Customized Solution and Ethical Practices" 
        Icon={FiCpu} 
      />
    </div><br/><br/><br/><br/><br/><br/>

      <div class="aboutHome">
        <div class="aboutHomeContent">
          <h2 class="heading">About Us</h2>
            <p class="para">Welcome to GroGlobally, where creativity and technology come together to drive unparalleled growth for your brand. We provide well-researched, optimized, and creative content to enhance your online visibility. Our cutting-edge business models include digital advertising, social media strategy, SEO, content writing, and business research. As a customer-facing digital marketing agency, we aim to make marketing tools accessible and economical for startups and entrepreneurs. At GroGlobally, we’re committed to providing strategic assessments tailored to your specific business needs. Join us to build a strong digital presence that drives growth and elevates your brand.

            </p>
          <Link to={'/about'}><button className="btn-home">Know More</button></Link>
        </div>
        <img src={home3} className="about-image"></img>
      </div><br/><br/><br/><br/>
      <div class="blog" style={{display:'flex',alignItems:'center',color:'white'}}>
        <img src={blog3} className="blog-image"></img>
        <div class="blogContent">
          <h2 class="heading2">Check Out our blogs<br/> and Articles</h2>
          <p class="para">Check out our specially crafted blogs from our team of specialized writers.

          </p>
          <Link to={'/articles'}><button className="btn-home">See Now</button></Link>
        </div>
        
      </div><br/><br/><br/><br/>
  
    {/* brand logos */}
    <Brand></Brand><br/><br/><br/><br/>
    <HomeDiv></HomeDiv><br/><br/><br/><br/>
    <HomeQues></HomeQues><br/><br/><br/><br/>
    
  </div>
  
  )
}

export default Home