import React  from "react";
import './Policy.css';



const Policy = () => {
    document.title="GroGlobally | Policy";
    return(
        <div className="Main-policy">
           
            <div className="Policy-points">
                <h1>Our Policies</h1>
               
                        <h3> 1.Service Packages and Billing</h3>
                            
                                <h4>1.1 Package Details</h4>
                                <p>GroGlobally offers three social media management packages. These packages are billed monthly for a minimum of six months</p>
                           
                                <h4>1.2 Billing</h4>
                                <p>Payments are due monthly, on the same day as the first payment, and must be paid in advance</p>
                           
                                <h4>1.3 Early Termination</h4>
                                <p>If you cancel before six months, there will be a fee equal to 30% of the monthly plan cost</p>
                            
                    
                        <h3>2. Content Ownership and Usage</h3>
                                <h4>2.1 Produced Content</h4>
                                <p>All content created by GroGlobally (like graphics, captions, posts) belongs to GroGlobally unless stated otherwise</p>
                           
                                <h4>2.2 Advertising Usage</h4>
                                <p>GroGlobally can use the content created for you in our advertising unless you say no.</p>
                           
                                
                  
                        <h3>3. Confidential</h3>
                            <h4>3.1 Client Information</h4>
                            <p>We keep your information safe and won’t share it with others</p>
                    
                        <h3> 4.Communication</h3>
                            
                            <h4>4.1 Response Time</h4>
                            <p>We will reply to your questions within 24 hours during business hours</p>
                    
                            <h4>4.2 Client Responsibilty</h4>
                            <p>Please give us feedback and information on time so we can do our job wel</p>
                    
                            
                  
                        <h3>5. Limitation of Liability</h3>
                        <p>GroGlobally is not responsible for any loss in business, profits, or data.</p>
                        
                        <h3>6. Termination of Services</h3>
                        <p>We can stop working with you if there’s misconduct, non-payment, or if any terms are violated.</p>

                        <h3>7. Change Of Policy</h3>
                        <p>We might change these policies, and using our services means you agree to the new terms.</p>

                        <h3>If You have any question, PLease email us: <a href="mailto:info@groglobally.com?body=">info@groglobally.com</a></h3><br/><br/>
                   
            </div>
        </div>
    )
}
export default Policy;