import './HomeDiv.css';
import { Link } from 'react-router-dom';
import team from '../Images/teammember.png';

const HomeDiv = () => {
    return(
        
        <div className="main-div">
            <div className="inner-div">
            <h2 className="heading-div">"GroGlobally's professionalism and innovative approach make them the ideal partner for any business looking to excel in social media marketing."</h2>
            <br/>
                <div className='Credentials'>
                    <p className='name'>Ms.Sameeksha Manekar</p>
                    <p>Social Media Manager</p>
                
                </div>
            </div>
        
            <div className="inner-div">
                <h2 className="heading-div">"Working with GroGlobally has been a game-changer for our social media and lead generation efforts. Their team’s expertise and dedication are evident in the results we've achieved"</h2>
                <br/>
                <div className='Credentials'>
                    <p className='name'>Mr.Pratap Pawar</p>
                    <p>Guruji Air</p>
                
                </div>
               
                
            </div>
            <div className="inner-div">
                <h2 className="heading-div">" The team’s dedication and ability to tailor strategies specifically for us have made all the difference. We’re thrilled to have GroGlobally as a trusted partner in our journey.""</h2>
                <br/>
                <div className='Credentials'>
                    <p className='name'>Ms.Shrishti Bansal</p>
                    <p>BakestryBox</p>
                
                </div>
               
                
            </div>
            
        </div>
        
        
    )
}


export default HomeDiv;