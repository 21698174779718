import React from 'react'
import Player from './Player'
import './Story.css';
import './Player.css';
import { Link } from 'react-router-dom';
const Story = () => {
  return (
    <div className="story">
      <Player/>
      <div className="story-right">
      <div className="storyblue-line"></div>
        <h2>Don't Listen to us,</h2>
        <h2>Listen to our <span style={{ color: '#55B7CE' }} >Success story</span> </h2>
        <p>This is CA Prtik Sir, whom we helped to increase their online reach through various social media platform and ultimately proving the effectiveness of GroGlobally.</p>
        <Link to={'/services'}><button className="btn-home">Join Us Now</button></Link>
      </div>

    </div>
  )
}

export default Story